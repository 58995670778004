<template>
  <div :class="$route.path.includes('/order/') ? ' order_ip' : ''">
    <div
      v-if="!delivered"
      class="order-inProgress pl-0
    "
    >
      <div
        v-if="
          $route.path.includes('/order/') ? 'order-inProgress__order-type' : ''
        "
        class="
         order-inProgress__order-type
        "
      >
        {{ order.placedBy === "customers" ? "Customer" : "Business" }} Order
      </div>
      <div
        class="pt-2 pb-0 text-center order-inProgress__number "
        @click="goToOrder"
      >
        <strong>Customer Name: </strong>
        <span>{{ order.customerName }}</span>
      </div>
      <div
        class="pt-1 pb-0 text-center order-inProgress__number "
        @click="goToOrder"
      >
        <strong>Order# </strong>
        <span>{{ order.id }}</span>
      </div>
      <div class="order-inProgress__container pl-0 row">
        <div
          v-if="rider !== null"
          class="order-inProgress__container__baskytor text-center col p-0 pl-2 "
          :class="$route.path.includes('/order/') ? ' col-3 ml-auto' : 'col-3'"
        >
          <div class="order-inProgress__container__baskytor__image">
            <img :src="rider.profileImage" alt="user image" />
          </div>
          <div class="order-inProgress__container__baskytor__name">
            {{ rider.name }}
          </div>
          <div
            class="order-inProgress__container__baskytor__ratings overlay-menu__ratings"
          >
            <div
              class="overlay-menu__ratings__stars__med overlay-menu__ratings__stars__med--silver"
            >
              <i
                v-for="index in 5"
                class="fa fa-star"
                v-bind:class="getGoldClass(rider.avgCustomerRating, index)"
                :key="index"
              ></i>
            </div>
          </div>
          <div class="order-inProgress__container__baskytor__rating-text">
            {{ rider.avgCustomerRating }}
          </div>
        </div>
        <div
          v-else
          class="order-inProgress__container__baskytor text-center col p-0 pt-2 pl-3"
          :class="$route.path.includes('/order/') ? ' col-3 ml-auto' : 'col-3'"
        >
          <div class="order-inProgress__container__baskytor__image">
            <loader
              class="order-inProgress__container__baskytor__image--loader"
            />
          </div>
          <div class="order-inProgress__container__baskytor__loader">
            Finding you a Baskytor
          </div>
        </div>
        <div
          class="order-inProgress__container__status col h-auto px-0"
          :class="
            $route.path.includes('/order/') ? ' col-8 pb-0 mr-auto' : 'col-9'
          "
        >
          <div class="order-inProgress__container__status__points">
            <div
              :class="
                getStatus(order.status) === 1
                  ? 'order-inProgress__container__status__point--active'
                  : getStatus(order.status) > 1
                  ? 'order-inProgress__container__status__point--complete'
                  : ''
              "
              class="order-inProgress__container__status__point"
            >
              <img
                :src="
                  getImgUrl(
                    getStatus(order.status) > 0
                      ? 'rider-icon-colored'
                      : 'rider-icon-silver'
                  )
                "
                alt="Rider Icon"
              />
            </div>
            <div
              class="order-inProgress__container__status__point"
              :class="
                getStatus(order.status) === 2
                  ? 'order-inProgress__container__status__point--active'
                  : getStatus(order.status) > 2
                  ? 'order-inProgress__container__status__point--complete'
                  : ''
              "
            >
              <img
                :src="
                  getImgUrl(
                    getStatus(order.status) > 1
                      ? 'vendor-icon-colored'
                      : 'vendor-icon-silver'
                  )
                "
                alt="vendor-icon"
              />
            </div>
            <div
              class="order-inProgress__container__status__point"
              :class="
                getStatus(order.status) === 3
                  ? 'order-inProgress__container__status__point--active'
                  : getStatus(order.status) > 3
                  ? 'order-inProgress__container__status__point--complete'
                  : ''
              "
            >
              <img
                :src="
                  getImgUrl(
                    getStatus(order.status) > 2
                      ? 'container-icon-colored'
                      : 'container-icon-silver'
                  )
                "
                alt="container-icon"
              />
            </div>
            <div
              class="order-inProgress__container__status__point"
              :class="
                getStatus(order.status) === 4
                  ? 'order-inProgress__container__status__point--active'
                  : getStatus(order.status) > 4
                  ? 'order-inProgress__container__status__point--complete'
                  : ''
              "
            >
              <img
                :src="
                  getImgUrl(
                    getStatus(order.status) > 3
                      ? 'rider-icon-colored'
                      : 'rider-icon-silver'
                  )
                "
                alt="rider-icon"
              />
            </div>
            <div
              class="order-inProgress__container__status__point"
              :class="
                getStatus(order.status) === 5
                  ? 'order-inProgress__container__status__point--complete'
                  : ''
              "
            >
              <img
                :src="
                  getImgUrl(
                    getStatus(order.status) > 4
                      ? 'delivered-icon-colored'
                      : 'delivered-icon-silver'
                  )
                "
                alt="delivered-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <div
          v-if="order.status !== 'Delivered'"
          class="col-xl-8 col-sm-12 info_text"
        >
          <div class="orderStatus__wrapper">
            <div
              class="order-inProgress__info-text order-inProgress__info-text--order-status"
            >
              {{ order.status }}
            </div>
            <div
              class="order-inProgress__time order-inProgress__time--order-status mb-0"
            >
              {{ getEstimatedTime() }}
            </div>
          </div>
        </div>
        <div
          v-if="!$route.path.includes('/order/')"
          class="col-xl-4 col-sm-12 order_button"
        >
          <button @click="goToOrder()" class="order-inProgress__button px-3">
            View Details
          </button>
        </div>
      </div>
    </div>
    <div v-if="delivered && $route.path.includes('/order-status/')">
      <div class="order-delivered  px-0">
        <div @click="goToOrder()" class="py-3 order-delivered__container mx-3">
          <div class="row">
            <div class="order-delivered__container__baskytor text-center col ">
              <div class="order-delivered__container__baskytor__image">
                <img :src="rider.profileImage" alt="user image" />
              </div>
              <div class="order-delivered__container__baskytor__name">
                {{ rider.name }}
              </div>
              <div
                class="order-inProgress__container__baskytor__ratings overlay-menu__ratings"
              >
                <div
                  class="overlay-menu__ratings__stars__small overlay-menu__ratings__stars__small--silver"
                >
                  <i
                    v-for="index in 5"
                    class="fa fa-star"
                    v-bind:class="getGoldClass(rider.avgCustomerRating, index)"
                    :key="index"
                  ></i>
                </div>
              </div>
              <div class="order-inProgress__container__baskytor__rating-text">
                {{ rider.avgCustomerRating }}
              </div>
            </div>
            <div class="col  text-center">
              <div class="order-delivered__container__name">
                {{ order.customerName }}
              </div>
              <div class="order-delivered__container__order">
                <strong> Order# </strong>{{ order.id }}
              </div>
              <div class="order-delivered__container__image pt-1">
                <img
                  class="img-fluid"
                  src="@/assets/static-assets/profile/Verified Button@3x.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div
              v-if="!$route.path.includes('/order/')"
              class=" mx-auto order_button"
            >
              <button
                @click="goToOrder()"
                class="order-inProgress__button px-3"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import BaskytProgress from "@/view/components/common/BaskytProgress";

const bc = new BroadcastChannel("reset_timer");
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}single-order-status`,
  props: {
    order: Object,
    delivered: Boolean,
    shadow: Boolean,
    rider: Object
  },
  components: {
    loader: BaskytProgress
  },
  data() {
    return {
      timeToNext: null,
      resetTimer: false
    };
  },
  computed: {
    ...mapState({
      region: state => {
        if (state.regions.region === "") {
          return "";
        }
        return state.regions.region ? "US" : "PK";
      }
    })
  },
  methods: {
    getImgUrl(image) {
      const images = require.context(
        "@/assets/static-assets/app-icons/",
        false,
        /\.png$/
      );
      return images(`./${image}.png`);
    },
    getGoldClass(total, key) {
      return key <= total ? "gold" : "";
    },
    getStatus(statusText) {
      let status = 0;
      switch (statusText) {
        case "Pending":
          status = 0;
          break;
        case "Confirmed":
          status = 1;
          break;
        case "Assembling":
          status = 2;
          break;
        case "Picked Up":
          status = 4;
          break;
        case "Reached Customer":
          status = 5;
          break;
        case "Delivered":
          status = 5;
          break;
        case "Cancelled":
          status = 5;
          break;
        default:
          status = 0;
      }
      return status;
    },
    getEstimatedTime() {
      const scope = this;
      this.resetTimer = false;
      const { status, time: timeTemp, rider } = this.order;
      let time = timeTemp;

      if (this.region === "US") {
        time /= 1000;
      }
      const startTime = parseInt(time / 60, 10);
      const end = startTime + 3;
      this.timeToNext = `${startTime} - ${end} mins`;

      if (rider === null) {
        return "Looking for Baskytor";
      }
      if (rider !== null && status === "Pending") {
        return "Waiting for Baskytor to accept the order";
      }
      bc.onmessage = function onmessage(ev) {
        scope.resetTimer = true;
      };
      if (!this.resetTimer) return `Est time: ${this.timeToNext}`;
      return "Est time: 0 mins";
    },
    goToOrder() {
      if (!this.$route.path.includes("/order/"))
        this.$router.push(`/order/${this.order.id}`);
    }
  }
};
</script>
<style lang="scss" scoped></style>
