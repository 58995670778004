import { render, staticRenderFns } from "./OrderStatusSingle.vue?vue&type=template&id=7a06bbad&scoped=true&"
import script from "./OrderStatusSingle.vue?vue&type=script&lang=js&"
export * from "./OrderStatusSingle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a06bbad",
  null
  
)

export default component.exports